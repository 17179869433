exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-catalogue-artists-tsx": () => import("./../../../src/pages/catalogue/artists.tsx" /* webpackChunkName: "component---src-pages-catalogue-artists-tsx" */),
  "component---src-pages-catalogue-artworks-tsx": () => import("./../../../src/pages/catalogue/artworks.tsx" /* webpackChunkName: "component---src-pages-catalogue-artworks-tsx" */),
  "component---src-pages-events-archive-tsx": () => import("./../../../src/pages/events/archive.tsx" /* webpackChunkName: "component---src-pages-events-archive-tsx" */),
  "component---src-pages-events-upcoming-tsx": () => import("./../../../src/pages/events/upcoming.tsx" /* webpackChunkName: "component---src-pages-events-upcoming-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-articles-tsx": () => import("./../../../src/pages/search/articles.tsx" /* webpackChunkName: "component---src-pages-search-articles-tsx" */),
  "component---src-pages-search-artists-tsx": () => import("./../../../src/pages/search/artists.tsx" /* webpackChunkName: "component---src-pages-search-artists-tsx" */),
  "component---src-pages-search-artworks-tsx": () => import("./../../../src/pages/search/artworks.tsx" /* webpackChunkName: "component---src-pages-search-artworks-tsx" */),
  "component---src-pages-search-events-tsx": () => import("./../../../src/pages/search/events.tsx" /* webpackChunkName: "component---src-pages-search-events-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-main-category-tsx": () => import("./../../../src/templates/main-category.tsx" /* webpackChunkName: "component---src-templates-main-category-tsx" */)
}

